
const Footer = () => {
    return (
        <div style={{
            backgroundColor: "#323232",
            color: "#ebebeb",
            padding: 10
        }}>
            <div >Made by AddAI team, version: 1.1.0</div>
        </div>
    )
}

export default Footer;
