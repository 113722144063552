import axios from "axios";
import Keycloak from "./Keycloak";

export const axiosClient = axios.create({
    baseURL: (!window.g_viaBaseUrl?.startsWith("%") ? window.g_viaBaseUrl : null) ?? process.env.REACT_APP_BASE_URL ?? "/tf"
});

axiosClient.interceptors.request.use(
    config => {
        config.headers = {
            Authorization: `Bearer ${Keycloak.token}`
        };
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use((response) => {
    return response;
}, error => {
        return Promise.reject(error);
});
