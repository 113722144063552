import {Popover, Typography} from "@mui/material";
import React, {useState} from "react";

const MaterialPopover = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Typography
                component="span"
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                    },
                    ...(props.mark && {backgroundColor: props.mark})
                }}
                onClick={(event) => setAnchorEl(event.currentTarget)}>
                {props.content}
            </Typography>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                {...props.container && {container: props.container}}
            >
                {props.children}
            </Popover>
        </React.Fragment>
    );
}

export default MaterialPopover;