import React, {useContext, useEffect, useState} from 'react';
import ProjectsMenu from '../Menu/ProjectsMenu';
import ProjectContext from '../../context/project-context';
import keycloak from "../../api/Keycloak";
import LogoutMenu from '../Menu/LogoutMenu';
import {Button, Grid, IconButton, styled} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsIcon from '@mui/icons-material/Settings';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import * as Api from '../../api/Api';
import {useNavigate} from "react-router-dom";
import {Compare, HelpOutline} from "@mui/icons-material";
import ConfirmDialog from "../dialogues/ConfirmDialog";

const StyledIconButton = styled(IconButton)({
    maxHeight: "45px",
    border: '1px solid rgb(153, 153, 153)',
    backgroundColor: 'white',
    color: '#999999',
    borderRadius: "5px",
    fontSize: 15
});

const ProjectSelect = () => {
    const [logoutMenuOpen, setLogoutMenuOpen] = useState(false);
    const [logoutOpenAnchorE1, setLogoutAnchorEl] = useState(null);
    const [projectsAnchorEl, setProjectsAnchorEl] = useState(null);
    const [projectsMenuOpen, setProjectsMenuOpen] = useState(false);
    const [userProfile, setUserProfile] = useState("");
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const {projects, selectedProject, setSelectedProject} = useContext(ProjectContext);

    const navigate = useNavigate();

    useEffect(() => {
        keycloak.loadUserProfile()
            .then((response) => {
                setUserProfile(response);
            })
            .catch(error => {
                console(error.response);
            });
    }, [])

    const handleProjectsMenuClick = (event) => {
        setProjectsMenuOpen(true);
        setProjectsAnchorEl(event.currentTarget);
    };

    const handleLogoutClicked = (event) => {
        setLogoutMenuOpen(true);
        setLogoutAnchorEl(event.target);
    };

    return (
        <React.Fragment>
                <Grid container alignItems="center" columnSpacing={2} justifyContent="right">
                    <Grid item>
                        <StyledIconButton
                            onClick={handleProjectsMenuClick}>
                            {selectedProject ? selectedProject.name : ""}
                            <ArrowDropDownIcon
                            />
                        </StyledIconButton>
                    </Grid>
                    <Grid item
                          onClick={() => {
                              navigate(Api.SETS_TESTING);
                          }}>
                        <Button startIcon={<CompareArrowsIcon style={{fontSize: '30px'}}/>} component="label">
                            Testování sad
                        </Button>
                    </Grid>
                    <Grid item
                          onClick={() => {
                              navigate(Api.TESTS_SUMMARY);
                          }}>
                        <Button startIcon={<Compare style={{
                            fontSize: '30px'
                        }}/>} component="label">
                            Porovnání testů
                        </Button>
                    </Grid>
                    <Grid item
                          onClick={() => {
                              navigate(Api.SETS_MANAGEMENT);
                          }}>
                        <Button startIcon={<SettingsIcon style={{
                            fontSize: '30px'
                        }}/>} component="label">
                            Správa sad
                        </Button>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setOpenConfirmDialog(true)}>
                            <HelpOutline fontSize="large"
                                         sx={{color: (theme) => theme.palette.primary.main, fontSize: '40px'}}/>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={handleLogoutClicked}
                            style={{
                                borderRadius: 30,
                                width: "40px",
                                height: "40px",
                                color: '#58A813',
                                border: '1px solid rgb(88, 168, 19)',
                                fontSize: "20px",
                                lineHeight: "20px",
                                fontFamily: "Roboto"
                            }}
                        >
                            {(userProfile
                                && userProfile.firstName
                                && userProfile.lastName) ?
                                (userProfile.firstName.substring(0, 1).concat(userProfile.lastName.substring(0, 1)))
                                : "?"}
                        </IconButton>
                    </Grid>
                </Grid>
                <LogoutMenu
                    open={logoutMenuOpen}
                    setOpen={setLogoutMenuOpen}
                    anchorEl={logoutOpenAnchorE1}
                    setAnchorEl={setLogoutAnchorEl}
                />
                <ProjectsMenu
                    open={projectsMenuOpen}
                    anchorEl={projectsAnchorEl}
                    setOpen={setProjectsMenuOpen}
                    setAnchorEl={setProjectsAnchorEl}
                    setSelectedProject={setSelectedProject}
                    projects={projects}
                />
            <ConfirmDialog
                isDownloadable={true}
                href="/Testing tool - manual.pdf"
                download="Testing tool - manual.pdf"
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                title="Návod k používání Testing Toolu"
                subtitle={"Testing tool - manual.pdf"}
            />
        </React.Fragment>);
}

export default ProjectSelect;
