import {styled, Table, TableCell, tableCellClasses, TableHead, TableRow} from "@mui/material";
import React from "react";
import MaterialPopover from "../controls/Popover";
import TestDetails from "../TestDetails";

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#E5E5E5",
        color: "#333333",
        fontWeight: 1000
    },
}));

export default function useTable(records, headCells, styled) {

    const TblContainer = props => (
        <Table
            sx={{
                ...(props.display && {width: props.display}),
                ...(props.width && {width: props.width}),
                borderCollapse: 'unset'
            }}
            {...props}
        >{props.children} </Table>
    )

    const TblHead = props => {
        const {align} = props;
        return (
            <TableHead>
                <TableRow key={Math.random()}>
                    {headCells.map(column =>
                        styled === true ?
                            column.firstOnly === true && props.index > 0 ?
                                null :
                                (<StyledTableCell
                                    key={column.label}
                                    {...(align && {align: align})}
                                    sx={{
                                        color: '#333333',
                                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                                        ...(column.width && {width: column.width}),
                                        ...(column.minWidth && {minWidth: column.minWidth}),
                                        ...(column.maxWidth && {maxWidth: column.maxWidth})
                                    }}
                                >
                                    {column.id === 'Info' ?
                                        <MaterialPopover content={column.label}>
                                            <TestDetails
                                                test={props.test}
                                                overrideResponseIds={props.overrideResponseIds}
                                                overrideDataSetIntents={props.overrideDataSetIntents}
                                            />
                                        </MaterialPopover> : column.label}
                                    {column.subLabel ? (
                                        <span><br/>{column.subLabel}</span>
                                    ) : null}
                                </StyledTableCell>)
                            : (<TableCell
                                    key={column.label}
                                    {...(align && {align: align})}
                                    sx={{
                                        color: '#333333',
                                        fontWeight: 600,
                                    }}
                                >{column.label}</TableCell>
                            ))}
                </TableRow>
            </TableHead>
        )
    };

    return {
        TblContainer,
        TblHead,
    }
}