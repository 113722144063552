export const isResponseIdSettable = (answerId, dataSetItem) => {
    const dataSetAnswerId = dataSetItem.overriddenResponseId ?? dataSetItem.responseId ?? "Undefined";
    return dataSetAnswerId === answerId;
}

export const isIntentSettable = (intent, dataSetItem) => {
    const dataSetIntent = dataSetItem.overriddenIntent ?? dataSetItem.intent ?? "Undefined";
    return dataSetIntent === intent;
}

export const cellColor = (score) => {
    if (score === 1.00) {
        return "rgb(99,190,123)";
    } else if (score >= 0.95 && score < 1.00) {
        return "rgb(119,196,89)";
    } else if (score >= 0.90 && score < 0.95) {
        return "rgb(138,202,90)";
    } else if (score >= 0.85 && score < 0.90) {
        return "rgb(158,210,127)";
    } else if (score >= 0.80 && score < 0.85) {
        return "rgb(177,213,128)";
    } else if (score >= 0.75 && score < 0.80) {
        return "rgb(197,219,129)";
    } else if (score >= 0.70 && score < 0.75) {
        return "rgb(216,224,130)";
    } else if (score >= 0.65 && score < 0.70) {
        return "rgb(236,230,131)";
    } else if (score >= 0.60 && score < 0.65) {
        return "rgb(255,235,132)";
    } else if (score >= 0.55 && score < 0.60) {
        return "rgb(254,224,129)";
    } else if (score >= 0.50 && score < 0.55) {
        return "rgb(253,213,127)";
    } else if (score >= 0.45 && score < 0.50) {
        return "rgb(253,202,125)";
    } else if (score >= 0.40 && score < 0.45) {
        return "rgb(252,191,123)";
    } else if (score >= 0.35 && score < 0.40) {
        return "rgb(252,180,121)";
    } else if (score >= 0.30 && score < 0.35) {
        return "rgb(251,170,119)";
    } else if (score >= 0.25 && score < 0.30) {
        return "rgb(250,159,117)";
    } else if (score >= 0.20 && score < 0.25) {
        return "rgb(250,148,115)";
    } else if (score >= 0.15 && score < 0.20) {
        return "rgb(249,138,113)";
    } else if (score >= 0.10 && score < 0.15) {
        return "rgb(249,128,111)";
    } else if (score >= 0.5 && score < 0.10) {
        return "rgb(248,118,109)";
    } else {
        return "rgb(248,105,107)";
    }
}