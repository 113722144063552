import { Button, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Dialog from "../controls/Dialog";

const StyledSpan = styled(Typography)({
    color: 'black',
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    padding: '2px 6px',
    marginLeft:'2px'
});

const TestResultsDialog = (props) => {
    const { open, setOpen, report, infoMessages, progress, itemsSize } = props;
    const progressBarPercentSize=  {
        width: '100%',
    };
    return (
        <React.Fragment>
            <Dialog
                title={"Výsledek testu"}
                open={open}
                setOpen={setOpen}
                textAlign="center">
                <DialogContent
                    sx={{ textAlign: "center" }}>

                    <div className="wrapper">
                        <div className="progress-bar">
                            <span className="progress-bar-fill" style={progressBarPercentSize}>100%</span>
                        </div>
                    </div>
                    <Typography
                        display="block"
                        component="h5">
                        Test proběhl úspěšně, nalezené konflikty:
                    </Typography>
                    <br />
                    {report ?
                        <Grid container>
                            <Typography
                                sx={{ color: 'rgba(255, 167, 38, 0.5)' }}
                                variant="body1">
                                Rozdíl v 1 intentu proti sadě:
                                <StyledSpan
                                    variant="span"
                                    style={{ backgroundColor: 'rgba(255, 167, 38, 0.5)' }}>
                                    {report.testDatasetIntentConflicts}
                                </StyledSpan>
                            </Typography>
                            <Typography
                                sx={{ color: 'rgba(2, 136, 209, 0.5)' }}
                                variant="body1">
                                Rozdíl v confidence 1 a 2 intentu menší než 40%:
                                <StyledSpan
                                    variant="span"
                                    style={{ backgroundColor: 'rgba(2, 136, 209, 0.5)' }}>
                                    {report.testIntentsConfidenceConflicts}
                                </StyledSpan>
                            </Typography>
                            <Typography
                                sx={{ color: 'rgba(211, 47, 47, 0.5)' }}
                                variant="body1">
                                Rozdíl ID odpovědi proti sadě:
                                <StyledSpan
                                    variant="span"
                                    style={{ backgroundColor: 'rgba(211, 47, 47, 0.5)' }}>
                                    {report.testDataSetResponseIdConflicts}
                                </StyledSpan>
                            </Typography>
                            <code style={{ height: '250px', width: '100%', overflow:'auto', msScrollbarBaseColor:'gold', fontFamily:'sans-serif', padding:'10px', textAlign: 'left' }}>
                                <br/>
                                <b>Test log:</b>
                                <br/>
                                { infoMessages.map(message => <div>{message}</div>) }
                            </code>
                        </Grid>
                        : <Typography>Nepodařilo při testování vytvořit report</Typography>}
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: '10px' }}
                        onClick={() => setOpen(false)}>
                        Zobrazit výsledky
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default TestResultsDialog;