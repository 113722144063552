import { TablePagination } from "@mui/material";
import React, { useState } from "react";

export default function usePagination(records, headCells, styled) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const resetPagination= ()=>{
        setPage(0);
        setTotalCount(0);
    }

    const TblPagination = (props) => {
        return (
            <TablePagination
                sx={{
                    '& .MuiTablePagination-toolbar':{
                        alignItems: 'baseline'
                    }
                }}
                labelRowsPerPage="Počet řádků na stránku"
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        );
    };

    return {
        setTotalCount,
        totalCount,
        rowsPerPage,
        page,
        resetPagination,
        TblPagination
    };
}
