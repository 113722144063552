import {Button, Grid, IconButton, Paper, styled} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import useTable from '../../components/hooks/useTable';
import * as dataSetService from '../../services/dataSetService';
import * as formattingUtils from "../../utils/formattingUtils";
import UploadFile from "@mui/icons-material/UploadFile";
import '../table.css';
import ArchiveIcon from '@mui/icons-material/Archive';
import useAlert from "../../components/hooks/useAlert";
import usePagination from "../../components/hooks/usePagination";
import ConfirmDialog from "../../components/dialogues/ConfirmDialog";
import ProgressDialog from "../../components/dialogues/ProgressDialog";
import ProjectContext from "../../context/project-context";
import {Download} from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
        paddingBottom: '6px',
        paddingTop: '6px'
    }
}));

const StyledTableBody = styled(TableBody)(({theme}) => ({
    "& .MuiTableRow-root:hover": {
        backgroundColor: '#58A8131A',
        color: 'white',
        cursor: 'pointer'
    }
}));

const testingSetsColumns = [
    {id: 'set_name', label: 'Název sady'},
    {id: 'changed_at', label: 'Datum vytvoření'},
    {id: 'author', label: 'Autor'},
    {id: 'action', label: 'Archivace'}

];

const SetsManagement = (props) => {
    const [dataSets, setDataSets] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [infoMessages, setInfoMessages] = useState([]);
    const {selectedProject} = useContext(ProjectContext);
    const {TblContainer, TblHead} = useTable([], testingSetsColumns, false);
    const {
        setTotalCount,
        rowsPerPage,
        page,
        TblPagination
    } = usePagination();
    const {
        Alert,
        status,
        showSuccess,
        showError,
    } = useAlert();

    useEffect(() => {
        props.setTitle("Správa sad lite");
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        getDataSets();
        //eslint-disable-next-line
    }, [rowsPerPage, page, selectedProject]);

    const getDataSets = () => {
        if (selectedProject)
            dataSetService.getAll(selectedProject.id, page, rowsPerPage)
                .then((response) => {
                    const result = response.data;
                    if (result) {
                        setTotalCount(result.totalCount);
                        setDataSets(result.items);
                    }
                })
                .catch(error => {
                    showError(error);
                });
    };

    const handleFileUpload = (e) => {
        e.preventDefault();
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        let formData = new FormData();
        formData.append('file', file);
        setOpenProgressDialog(true);
        setTimeout(function () {
            dataSetService.uploadFile(selectedProject.id, formData)
                .then((response) => {
                    showSuccess();
                    getDataSets();
                }).catch(error => {
                showError(error);
            }).finally(() => {
                setOpenProgressDialog(false);
            })
        }, 2000);
    };


    const handleDataSetArchive = (dataSetId) => {
        dataSetService.archive(dataSetId)
            .then((response) => {
                showSuccess();
                getDataSets();
            }).catch(error => {
            showError(error);
        });
    }

    return (
        <Paper variant='outlined' sx={{flex: '1', borderRadius: '20px'}}>
            {status ? <Alert/> : null}
            <Grid container>
                <Grid sx={{m: 2}} item container alignItems="center" justifyContent="left" xs={12}>
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<UploadFile/>}
                        component="label"
                        sx={{
                            borderRadius: '5px',
                            marginRight: '10px'
                        }}>
                        Import sady
                        <input
                            type="file"
                            hidden
                            accept=".xlsx"
                            onChange={(e) => handleFileUpload(e)}
                            onClick={(event) => {
                                event.target.value = null
                            }}
                        />
                    </Button>
                    <Button
                        size="small"
                        startIcon={<Download/>}
                        component="label">
                        <a style={{textDecoration: 'none', color: 'inherit'}}
                           href="./Template.xlsx"
                           download="Template.xlsx">
                            Stáhnout vzor sady</a>
                    </Button>
                </Grid>
            </Grid>
            <TblContainer>
                <TblHead/>
                <StyledTableBody>
                    {dataSets && dataSets.map(key => (
                        <TableRow
                            key={key.id}
                            onClick={() => setSelectedRow(key)}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <StyledTableCell>{key.name}</StyledTableCell>
                            <StyledTableCell>
                                {formattingUtils.formatDate(key.updated)}
                            </StyledTableCell>
                            <StyledTableCell>{key.updatedBy}</StyledTableCell>
                            <StyledTableCell>
                                <IconButton onClick={() => setOpenConfirmDialog(true)}>
                                    <ArchiveIcon/>
                                </IconButton>
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </StyledTableBody>
            </TblContainer>
            <TblPagination/>
            <ConfirmDialog
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                title="Archivovat data?"
                subtitle={"Opravdu chcete archivovat " + selectedRow.name + "?"}
                confirmAction={() => handleDataSetArchive(selectedRow.id)}
            />
            <ProgressDialog
                open={openProgressDialog}
                setOpen={setOpenProgressDialog}
                title="Import sady"
                subtitle={"Probíhá import datové sady."}
                infoMessages={infoMessages}
                setInfoMessages={setInfoMessages}
                progress="0"
                itemsSize="0"
            />
        </Paper>
    );
}

export default SetsManagement;