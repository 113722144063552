import { Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useShowTestResultsOnHover from "./hooks/useShowTestResultsOnHover";
import CachedIcon from '@mui/icons-material/Cached';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MaterialPopover from "./controls/Popover";

const ResponseOnHover = (props) => {
    const [cached, setCached] = useState(!props.item.isTestDatasetDialogIdsConflict);
    const [resolved, setResolved] = useState(props.item.isTestDatasetDialogIdsConflictResolved);
    const { setChecked, checked, isHover, ShowTestResultsOnHover } = useShowTestResultsOnHover();

    useEffect(() => {
        setChecked(cached || resolved)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isHover)
            override();
        //eslint-disable-next-line
    }, [cached]);

    useEffect(() => {
        if (isHover)
            resolve();
        //eslint-disable-next-line
    }, [resolved]);

    const resolve = () => {
        const data = []
        if (checked) {
            data.push({
                testResultItemId: props.id,
                isTestDatasetDialogIdsConflictResolved: true
            })
        }
        else {
            data.push({
                testResultItemId: props.id,
                isTestDatasetDialogIdsConflictResolved: false
            })
        }
        props.updateTestResult({ items: data })
    }

    const override = () => {
        const data = [];
        if (checked) {
            data.push({  dataSetItemId: props.dataSetItem.id, testResultItemId: props.id, overriddenResponseId: props.answerId })
        } else {
            data.push({  dataSetItemId: props.dataSetItem.id, testResultItemId: props.id, overriddenResponseId: props.dataSetItem.responseId })
        }
        props.updateDataSet({ items: data })
    }


    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (!event.target.checked) {
            if (cached)
                setCached(false)
            else if (resolved)
                setResolved(false)
        }
        else
            setResolved(true)
    };

    return (
        <React.Fragment>
            <ShowTestResultsOnHover
                content={props.content}
                                isHoverable={props.isHoverable}

                mark={resolved === true || (cached && props.dataSetItem.overriddenResponseId && 
                    props.dataSetItem.overriddenResponseId !==  props.item.dataSetResponseId)
                    ? { border: '2px solid rgba(211, 47, 47, 0.5)' } :
                    cached === false ?
                        { backgroundColor: 'rgba(211, 47, 47, 0.5)' } : null}
            >
                <Checkbox
                    size="small"
                    checked={checked}
                    onChange={(event) => handleChange(event)}
                    sx={{ padding: '0' }} />
                {!checked ?
                    <React.Fragment>
                            <CachedIcon
                            sx={{fontSize:20}}
                                onClick={() => {
                                    setChecked(true);
                                    setCached(true);
                                }}
                            /> 
                        <MaterialPopover content = {<InfoOutlinedIcon sx={{fontSize:20}}/>}>
                            <Typography sx={{ p: 2 }}>ID odpovědi dotazu v testu se liší od ID odpovědi v sadě</Typography>
                        </MaterialPopover>
                    </React.Fragment> : null}
            </ShowTestResultsOnHover>
        </React.Fragment>
    );
}

export default ResponseOnHover;