import { Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useShowTestResultsOnHover from "./hooks/useShowTestResultsOnHover";
import CachedIcon from '@mui/icons-material/Cached';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MaterialPopover from "./controls/Popover";

const IntentOnHover = (props) => {
    const [cached, setCached] = useState(!props.intentItem.isTestDatasetIntentsConflict );
    const [resolved, setResolved] = useState(props.intentItem.isTestDatasetIntentsConflictResolved);
    const { setChecked, checked, isHover, ShowTestResultsOnHover } = useShowTestResultsOnHover();

    useEffect(() => {
        setChecked(cached || resolved)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isHover)
            override();
        //eslint-disable-next-line
    }, [cached]);

    useEffect(() => {
        if (isHover)
            resolve();
        //eslint-disable-next-line
    }, [resolved]);

    const resolve = () => {
        const data = []
        if (checked) {
            data.push({
                testResultItemId: props.id,
                intents: [{id: props.intentItem.id, intent: props.intentItem.intent, isTestDatasetIntentsConflictResolved: true }]
            })
        }
        else {
            data.push({
                testResultItemId: props.id,
                intents: [{ id: props.intentItem.id, intent: props.intentItem.intent, isTestDatasetIntentsConflictResolved: false }]
            })
        }
        props.updateTestResult({ items: data })
    }

    const override = () => {
        const data = [];
        if (checked) {
            data.push({ dataSetItemId: props.dataSetItem.id, testResultItemId: props.id, overriddenIntent: props.intentItem.intent });
        } else {
            data.push({ dataSetItemId: props.dataSetItem.id, testResultItemId: props.id, overriddenIntent: props.dataSetItem.intent });
        }
        props.updateDataSet({ items: data })
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (!event.target.checked) {
            if (cached)
                setCached(false)
            else if (resolved)
                setResolved(false)
        }
        else
            setResolved(true)
    };

    return (
        <React.Fragment>
            <ShowTestResultsOnHover
                content={props.content}
                isHoverable={props.isHoverable}
                mark={resolved === true || (cached && props.dataSetItem.overriddenIntent && 
                    props.dataSetItem.overriddenIntent !== props.dataSetIntent)
                    ? { border: '2px solid rgba(255, 167, 38, 0.5)' } :
                    cached === false ?
                        { backgroundColor: 'rgba(255, 167, 38, 0.5)' } : null}
            >
                <Checkbox
                    size="small"
                    checked={checked}
                    onChange={(event) => handleChange(event)}
                    sx={{ padding: 0}} />
                {!checked ?
                    <React.Fragment key={Math.random()}>
                        {!props.isCacheable ?
                            <CachedIcon
                            sx={{
                                fontSize:20
                            }}
                                onClick={() => {
                                    setChecked(true);
                                    setCached(true);
                                }}
                            /> : null}
                        <MaterialPopover content = {<InfoOutlinedIcon sx={{fontSize:20}}/>}>
                            <Typography sx={{ p: 2 }}>{"1. intent dotazu v testu se liší od intentu v sadě."}</Typography>
                        </MaterialPopover>
                    </React.Fragment> : null}
            </ShowTestResultsOnHover>
        </React.Fragment>
    );
}

export default IntentOnHover;