import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "../src/App";
import 'typeface-roboto'

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <App />,
);

reportWebVitals();
