import React from "react";
import { Grid, Typography, Paper, styled } from '@mui/material';
import { useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import * as Api from '../api/Api';
import {Compare} from "@mui/icons-material";

const StyledTypography = styled(Typography)({
    fontSize: '30px',
    fontWeight: '400',
    lineHeight: '44px',
    letterSpacing: '0em',
    textAlign: 'center'
});

const StyledGrid = (props) => (
    <Grid
        item
        xs={3}
        sx={{
            border: 1,
            borderColor: '#CCCCCC',
            borderRadius: '4px',
            minWidth: '310px',
            minHeight: '350px',
            m: 1,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#F9FAFB'
            },
            '&:active': {
                cursor: 'pointer',
                backgroundColor: '#F2F2F2'
            }
        }}
        {...props}
    >
        {props.children}
    </Grid>
);

const MainView = (props) => {
    const navigate = useNavigate();

    return (
        <Paper sx={{
            borderRadius: '20px',
            display: 'flex',
            flex: '1'
        }}>
            <Grid container justifyContent="center" alignItems="center">
                <StyledGrid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    onClick={e => navigate(Api.SETS_TESTING)}>
                    <CompareArrowsOutlinedIcon sx={{ width: '65px', height: '65px', color: (theme) => theme.palette.primary.main }}></CompareArrowsOutlinedIcon>
                    <StyledTypography>Testování sad</StyledTypography>
                </StyledGrid>
                <StyledGrid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    onClick={e => navigate(Api.TESTS_SUMMARY)}>
                    <Compare sx={{ width: '65px', height: '55px', color: (theme) => theme.palette.primary.main }}></Compare>
                    <StyledTypography>Porovnání testů</StyledTypography>
                </StyledGrid>
                <StyledGrid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    onClick={e => navigate(Api.SETS_MANAGEMENT)}>
                    <SettingsOutlinedIcon sx={{ width: '65px', height: '55px', color: (theme) => theme.palette.primary.main }}></SettingsOutlinedIcon>
                    <StyledTypography>Správa sad</StyledTypography>
                </StyledGrid>
            </Grid>
        </Paper>
    );
}

export default MainView;