import {Box, DialogContent, Typography} from "@mui/material";
import React from "react";
import Dialog from "../controls/Dialog";

const ProgressDialog = (props) => {
    const {open, setOpen, title, subtitle, progress, itemsSize, infoMessages} = props;

    let progressBarPercentSize = {
        width: (progress / itemsSize) * 100 + '%',
    };

    if(progress === 1){
        progressBarPercentSize = {
            width: '0%'
        };
    }

    return (
        <React.Fragment>
            <Dialog
                title={title}
                textAlign="center"
                open={open}
                setOpen={setOpen}>
                <DialogContent lg={{textAlign: "center", width: '100%' }}>
                    <Typography variant="subtitle2">
                        {subtitle}
                    </Typography>
                    <div className="wrapper">
                        <div className="progress-bar">
                            <span className="progress-bar-fill" style={progressBarPercentSize}>{itemsSize && <div>{progress}/{itemsSize}</div>}</span>
                        </div>
                    </div>
                    <div>
                        <Box
                            component="img"
                            alt="Running foxie"
                            src="/running-foxie.gif"
                            sx={{
                                width: "180px",
                                height: "180px",
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                        />
                    </div>
                    <code style={{height: '250px', width: '100%', overflow:'auto', msScrollbarBaseColor:'gold',fontFamily:'sans-serif', padding:'10px', textAlign: "left" }}>
                        <br/>
                        <b>Test log:</b>
                        <br/>
                        { infoMessages.map(message => <div>{message}</div>)}
                    </code>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default ProgressDialog;