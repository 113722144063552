import { Button, Grid, styled, Typography } from "@mui/material";
import * as formattingUtils from "../utils/formattingUtils";
import CachedIcon from '@mui/icons-material/Cached';
import React from "react";

const StyledSpanKey = styled(Typography)({
    color: '#999999'
});
const StyledSpanValue = styled(Typography)({
    color: '#333333'
});

const TestDetails = (props) => {

    return (
        <Grid container
              sx={{
                  border: '2px solid #58A813',
                  borderRadius: '4px'
              }}>
            <React.Fragment>
                <Grid sx={{ "& span": { p: 0.5 }, m: 2 }} container item direction="column">
                    <StyledSpanKey
                        variant="span">Název testu:
                        <StyledSpanValue variant="span">{props.test.name}</StyledSpanValue>
                    </StyledSpanKey>
                    <StyledSpanKey
                        variant="span">Datum a čas:
                        <StyledSpanValue variant="span">{formattingUtils.convertUTCDateToLocalDate(props.test.executed)}</StyledSpanValue>
                    </StyledSpanKey>
                    <StyledSpanKey
                        variant="span">Prostředí:
                        <StyledSpanValue variant="span"> {props.test.env}</StyledSpanValue>
                    </StyledSpanKey>
                    <StyledSpanKey
                        variant="span">Klient:
                        <StyledSpanValue variant="span">{props.test.clientId}</StyledSpanValue>
                    </StyledSpanKey>
                </Grid>
                <Grid sx={{ "& button": { ml: 1, mb: 2 } }} container item direction="row" >
                    <Button
                        sx={{ backgroundColor: '#58A813' }}
                        startIcon={<CachedIcon fontSize="small" />}
                        size="small"
                        variant="contained"
                        onClick={()=>props.overrideDataSetIntents()}
                    >Propsat všechny intenty</Button>
                    <Button
                        sx={{ backgroundColor: '#58A813' }}
                        startIcon={<CachedIcon fontSize="small" />}
                        size="small"
                        variant="contained"
                        onClick={() => props.overrideResponseIds()}
                    >Propsat všechny ID</Button>
                </Grid>
            </React.Fragment>
        </Grid>
    )
}

export default TestDetails;