import React from "react";
import ProjectSelect from "../ProjectSelect/ProjectSelect";
import {AppBar, Box, Grid, Toolbar, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const APP_NAME = "TESTING FOXIE"

const Header = (props) => {
    const navigate = useNavigate();
    return (
        <AppBar
            sx={{
                backgroundColor: 'white',
                color: 'black',
            }}
            position="fixed">
            <Toolbar>
                <Grid container>
                    <Grid item container alignItems="center" justifyContent="left" spacing={2} xs={6}>
                        <Grid item
                              onClick={() => navigate("/")}>
                            <Box
                                component="img"
                                alt="Main Logo"
                                src="/logo.svg"
                                sx={{
                                    width: "36px",
                                    height: "36px",
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h5"
                                style={{
                                    fontSize: '24px',
                                    fontWeight: '500',
                                }}
                            >{APP_NAME} {props.title !== undefined ? ` - ${props.title}` : ""}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container
                          justifyContent='flex-end'
                          alignItems='center'
                          item xs={6}>
                        <React.Fragment>
                            <ProjectSelect/>
                        </React.Fragment>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
};

export default Header;