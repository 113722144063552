import {Typography} from "@mui/material";
import React from "react";
import ConfidenceOnHover from "./ConfidenceOnHover";
import * as formattingUtils from "../utils/formattingUtils";

const resolvedConflictStyle = {border: '2px solid rgba(2, 136, 209, 0.5)'}
const unresolvedConflictStyle = {backgroundColor: 'rgba(2, 136, 209, 0.5)'}

const Intent = (props) => {
    const {firstIntent, resultItemIndex, intentIndex, resultItem, intentItem, updateTestResult} = props;

    const resolvedMark = firstIntent.isIntentsCollision === false ?
        null : firstIntent.isIntentsCollisionResolved || !firstIntent.isIntentsCollision ?
            resolvedConflictStyle : unresolvedConflictStyle

    return (
        intentIndex === 0 ? (
            <ConfidenceOnHover
                mark={resolvedMark}
                isHoverable={resultItemIndex === 0}
                dataSetItem={resultItem.dataSetItem}
                intentItem={intentItem}
                id={resultItem.id}
                content={formattingUtils.scoreAverage(intentItem.score)}
                updateTestResult={updateTestResult}/>
        ) : (
            <Typography
                variant="body2"
                sx={{
                    ...(intentIndex === 1 &&
                    intentItem.isConfidenceAcceptable ?
                        null : unresolvedConflictStyle)
                }}
            >
                {formattingUtils.scoreAverage(intentItem.score)}
            </Typography>
        )
    )
}

export default Intent;