import { DialogTitle, Dialog as MUIDialoge } from '@mui/material';
import React from 'react';

const Dialog = (props) => {
    const { title, open,setOpen, textAlign } = props;

    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <MUIDialoge
            maxWidth="lg"
            fullWidth
            open={open}
            disableScrollLock={true}
            onClose={handleClose}>
            <DialogTitle
                {...textAlign && { textAlign: textAlign }}
            >{title}
            </DialogTitle>
            {props.children}
        </MUIDialoge>
    )
}

export default Dialog;