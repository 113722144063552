
import { createTheme } from '@mui/material';
import { green } from '@mui/material/colors';


export default createTheme({
    palette: {
        primary: {
            main: green[600],
        },
    },
});
