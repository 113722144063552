import {CircularProgress, FormHelperText, Grid, Paper, Slider, TableBody, TableHead, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useTable, {StyledTableCell} from "../../components/hooks/useTable";
import * as dataSetService from "../../services/dataSetService";
import * as formattingUtils from "../../utils/formattingUtils";
import * as UIUtils from "../../utils/UIUtils";
import useAlert from "../../components/hooks/useAlert";
import '../table.css';
import SelectOptions from "../../components/controls/SelectOptions";
import ProjectContext from "../../context/project-context";
import usePagination from "../../components/hooks/usePagination";
import HoverPopover from "../../components/controls/HoverPopover";

const initColumns = [
    {id: "question", label: "Dotazy", firstOnly: true, minWidth: '400px'},
    {id: "intents", label: "Intenty", firstOnly: true, minWidth: '300px',},
];

const TestsSummary = () => {
    const [testResults, setTestResults] = useState([]);
    const [error, setError] = React.useState("");
    const [maxValue, setMaxValue] = React.useState(10);
    const [value, setValue] = React.useState(5);
    const [dataFetched, setDataFetched] = useState(true);
    const [rowHeight, setRowHeight] = useState(0);
    const {totalCount, setTotalCount, rowsPerPage, page, resetPagination, TblPagination} = usePagination();
    const {TblContainer} = useTable([], initColumns, true);
    const containerRef = React.useRef();
    const {Alert, status, showError} = useAlert();
    const {
        selectedProject,
        selectedClient,
        selectedDataSet,
        selectedEnvironment,
    } = useContext(ProjectContext);

    const cellElementRef = node => {
        if (node !== null) {
            const rh = node.offsetHeight;
            if (rowHeight < rh) {
                setRowHeight(rh);
            }
            node.style.height = `${rowHeight}px`;
        }
    };

    const marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: `3`,
            label: `3`
        },
        {
            value: `5`,
            label: `5`
        },
        {
            value: `7`,
            label: `7`
        },
        {
            value: `10`,
            label: `10`
        },
    ];

    useEffect(() => {
        if (selectedProject && selectedClient && selectedDataSet && selectedEnvironment) {
            setRowHeight(0);
            getHistoricalTestResults();
            setError("");
        }
        //eslint-disable-next-line
    }, [selectedProject, selectedClient, selectedDataSet, selectedEnvironment, rowsPerPage, page]);

    useEffect(() => {
        if (selectedProject) {
            setTestResults([]);
            resetPagination();

        }
        //eslint-disable-next-line
    }, [selectedProject]);

    const getHistoricalTestResults = (newValue) => {
        setDataFetched(false);
        dataSetService
            .getTestResults(
                selectedDataSet,
                selectedProject.id,
                selectedClient,
                selectedEnvironment,
                page,
                rowsPerPage,
                newValue ?? value,
                true,
                true
            )
            .then((response) => {
                const result = response.data;
                if (result) {
                    setTestResults(result.items);
                    setTotalCount(result.totalCount);
                    setMaxValue(result.resultsCount > 10 ? 10 : result.resultsCount)
                    if (result.totalCount < page * rowsPerPage) {
                        resetPagination();
                    }
                    if (result.resultsCount > 0 && value > result.resultsCount)
                        setValue(result.resultsCount)
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
            setDataFetched(true);
        });
    };

    const handleChange = (event, newValue) => {
        if (newValue > maxValue && value === maxValue)
            setError("Error")
        else
            setError("")
        if (newValue <= maxValue) {
            setValue(newValue);
            getHistoricalTestResults(newValue);
        }
    };

    return (
        <Paper
            variant="outlined"
            ref={containerRef}
            sx={{
                border: "0",
                borderRadius: "20px",
                flex: "1",
                overflowX: 'auto',
                overflowY: 'auto'
            }}>
            {status ? <Alert/> : null}
            <Grid alignItems="center" justifyContent="start" sx={{p: 4}}>
                <Grid container alignItems="baseline" sx={{px: 1}}>
                    <Grid item container justifyContent="left" xs={6}>
                        <SelectOptions
                            errors={""}
                        />
                    </Grid>
                    <Grid item container justifyContent="right" alignItems="center" xs={6} spacing={2}>
                        <Grid item>
                            <Typography id="input-slider">
                                Počet historických testů:
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Slider
                                aria-label="Temperature"
                                value={value}
                                valueLabelDisplay="auto"
                                min={1}
                                defaultValue={value}
                                step={1}
                                max={10}
                                onChange={handleChange}
                                marks={marks}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            {error && <FormHelperText sx={{
                                color: 'red',
                                textAlign: 'center',
                                margin: 0,
                                padding: 0
                            }}>{maxValue === 1 ? `Nalezen pouze ${maxValue} test` :
                                `Nalezeno pouze ${maxValue} výsledky testů`}</FormHelperText>}
                        </Grid>
                    </Grid>

                </Grid>
                <TblContainer>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{border: 0, maxWidth: '1200px'}}>
                                {!dataFetched ?
                                    <React.Fragment>
                                        <Typography sx={{m: 5}}><CircularProgress/></Typography>
                                    </React.Fragment> :
                                    (dataFetched && totalCount === 0) ?
                                        <React.Fragment>
                                            <Typography sx={{m: 5}}>Nebyly nalezeny žádné výsledky</Typography>
                                        </React.Fragment> :
                                        testResults.map((result, resultIndex) => (
                                            <TblContainer key={`${result.id}_summary`}
                                                          sx={{
                                                              display: testResults.length <= 1 ? 'table' : 'inherit',
                                                              width: 'max-content'
                                                          }}>
                                                <TableHead>
                                                    <TableRow
                                                        key={Math.random()}>
                                                        {resultIndex === 0 && (initColumns.map(it =>
                                                                <StyledTableCell>
                                                                    {it.label}
                                                                </StyledTableCell>
                                                            )
                                                        )
                                                        }
                                                        <StyledTableCell
                                                            sx={{
                                                                minWidth: '100px',
                                                                maxWidth: '100px'
                                                            }}
                                                            align={"center"}
                                                        >
                                                            {formattingUtils.formatTimestampTestsCompare(result.executed)}
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {result.items.map((item, itemIndex) => (
                                                    <React.Fragment>
                                                        <TableBody key={`${itemIndex}_summary`}>
                                                            <TableRow key={`${result.id}${item.id}_summary`}>
                                                                {resultIndex === 0 ? (
                                                                    <TableCell
                                                                        ref={cellElementRef}
                                                                        sx={{
                                                                            minWidth: '400px',
                                                                            maxWidth: '400px',
                                                                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                                            ...(itemIndex === result.items.length - 1 && {borderBottom: 0})
                                                                        }}>
                                                                        <HoverPopover
                                                                            cell={cellElementRef}
                                                                            container={containerRef.current}
                                                                            content={item.dataSetItem.question ?? "Undefined"}>
                                                                            <Typography
                                                                                sx={{p: 2}}>{item.dataSetItem.question}</Typography>
                                                                        </HoverPopover>
                                                                    </TableCell>
                                                                ) : null}
                                                                {resultIndex === 0 ? (
                                                                    <TableCell
                                                                        ref={cellElementRef}
                                                                        sx={{
                                                                            ...(itemIndex === result.items.length - 1 && {borderBottom: 0})
                                                                        }}>
                                                                        {item.intents
                                                                            .slice(0, 2)
                                                                            .map((it, intentIndex) =>
                                                                                (<Typography
                                                                                        key={`${it.id}_summary`}
                                                                                        variant="body2"
                                                                                    >{it.intent}</Typography>
                                                                                ))}
                                                                    </TableCell>
                                                                ) : null}
                                                                <TableCell
                                                                    ref={cellElementRef}
                                                                    align='center'
                                                                    sx={{
                                                                        paddingX: '0',
                                                                        ...(itemIndex === result.items.length - 1 && {borderBottom: 0})
                                                                    }}>
                                                                    {item.intents.slice(0, 2).map((intent, intentIndex) =>
                                                                        (<Typography
                                                                                sx={{
                                                                                    backgroundColor: ` ${intentIndex === 0 ? UIUtils.cellColor(intent.score) : UIUtils.cellColor(1 - intent.score)}`
                                                                                }}
                                                                                key={`${intent.id}_summary`}
                                                                                variant="body2"
                                                                            >{intent.score}</Typography>
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </React.Fragment>
                                                ))}
                                            </TblContainer>
                                        ))}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </TblContainer>
                <TblPagination/>
            </Grid>
        </Paper>
    );
};

export default TestsSummary;
