import {Popover, Typography} from "@mui/material";
import React, {useState} from "react";

const HoverPopover = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const hoverRef = React.useRef();

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Typography
                ref={hoverRef}
                component="span"
                sx={{
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                    ...(props.mark && {backgroundColor: props.mark})
                }}
                onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
            >
                {props.content}

            </Typography>
            <Popover
                disablePortal={true}
                open={Boolean(anchorEl && hoverRef.current.clientHeight < hoverRef.current.scrollHeight)}
                disa
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                {...props.container && {container: props.container}}
                sx={{
                    pointerEvents: 'none'

                }}
            >
                {props.children}
            </Popover>
        </React.Fragment>
    );
}

export default HoverPopover;