export const UNDEFINED = "Undefined";
export const UND_DIALOGUE = `${UNDEFINED} dialogue`

export const scoreAverage = (value) => {
    if (value || value === 0) {
        return Number(value).toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2});
    } else {
        return "unknown";
    }
}

export const formatIntent = (value) => {
    if (value === undefined) {
        return `${UNDEFINED} intent`;
    } else {
        return `#${value.replaceAll("_", " ")}`;
    }
}

export const formatDate = (value) => {
    return new Intl.DateTimeFormat("cs-CZ", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    }).format(Date.parse(value));
}

export const formatTimestamp = (value) => {
    return new Intl.DateTimeFormat("cs-CZ", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }).format(Date.parse(value));
}

export const convertUTCDateToLocalDate = (timestamp) => {
    return new Intl.DateTimeFormat("cs-CZ", {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }).format(Date.parse(timestamp));
}

export const formatTimestampTestsCompare = (value) => {
    return new Intl.DateTimeFormat("cs-CZ", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }).format(Date.parse(value));
}