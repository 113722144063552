import { Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useShowTestResultsOnHover from "./hooks/useShowTestResultsOnHover";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MaterialPopover from "./controls/Popover";

const ConfidenceOnHover = (props) => {
    const [resolved, setResolved] = useState(props.intentItem.isIntentsCollisionResolved || !props.intentItem.isIntentsCollision);
    const { setChecked, checked, isHover, ShowTestResultsOnHover } = useShowTestResultsOnHover();

    useEffect(() => {
        setChecked(resolved)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isHover)
            resolve();
        //eslint-disable-next-line
    }, [resolved]);

    const resolve = () => {
        const data = []
        if (checked) {
            data.push({
                testResultItemId: props.id,
                intents: [{ id: props.intentItem.id, intent: props.intentItem.intent, isIntentsCollisionResolved: true }]
            })
        }
        else {
            data.push({
                testResultItemId: props.id,
                intents: [{ id: props.intentItem.id, intent: props.intentItem.intent, isIntentsCollisionResolved: false }]
            })
        }
        props.updateTestResult({ items: data })
    }

    const handleChange = (event) => {
        setChecked(event.target.checked)
        setResolved(event.target.checked)
    };

    return (
        <React.Fragment>
            <ShowTestResultsOnHover
                content={props.content}
                isHoverable={props.isHoverable}
                mark={props.mark}
            >
                <Checkbox
                    size="small"
                    checked={checked}
                    onChange={(event) => handleChange(event)}
                    sx={{ padding: '0' }} />
                {!checked ?
                    <MaterialPopover content = {<InfoOutlinedIcon sx={{fontSize:20}}/>}>
                    <Typography sx={{ p: 2 }}>{"Confidence 1. a 2. intentu se liší < 40%"}</Typography>
                </MaterialPopover>: null}
            </ShowTestResultsOnHover>
        </React.Fragment>
    );
}

export default ConfidenceOnHover;