import { Box, Typography } from "@mui/material";
import React, { useLayoutEffect, useRef, useState } from "react";

export default function useShowTestResultsOnHover(props) {
    const [isHover, setHover] = useState(false);
    const [checked, setChecked] = useState(false);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const ref = useRef(null);
    let timer;

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetHeight);
    }, []);

    const ShowTestResultsOnHover = (props) => {
        return (
            <div
                id={Math.random()}
                sx={{
                    border: '2px solid transient',
                    maxHeight: height,
                    maxWidth: width
                }}
                onMouseLeave={() => {
                    setHover(false);
                    clearTimeout(timer);
                }}
                onMouseEnter={() => {
                    timer = setTimeout(() => {
                        setHover(true);
                    }, 100)
                }}
            >
                {isHover && props.isHoverable && props.mark ?
                    <Box sx={{
                        minHeight: height,
                        minWidth: width,
                        ...(props.mark && { backgroundColor: props.mark })
                    }}>
                        {props.children}
                    </Box> : <Typography
                        ref={ref}
                        variant="body2"
                        sx={{
                            minHeight: height,
                            minWidth: width,
                            padding: '2px',
                            border: '2px solid transparent',
                            ...(props.mark && { backgroundColor: props.mark })
                        }}
                    >{props.content}
                    </Typography>
                }
            </div>
        );
    };

    return {
        setChecked,
        checked,
        isHover,
        setHover,
        setWidth,
        setHeight,
        ShowTestResultsOnHover
    };
}
