import React, {createContext, useEffect, useState} from 'react';
import useAlert from '../components/hooks/useAlert';
import * as projectService from '../services/projectService';

const ProjectContext = createContext({
    projects: [],
    selectedProject: "",
    previousProject: "",
    setSelectedProject: () => {
    },
    clients: [],
    setClients: () => {
    },
    selectedClient: "",
    setSelectedClient: () => {
    },
    environments: [],
    setEnvironments: () => {
    },
    selectedEnvironment: "",
    setSelectedEnvironment: () => {
    },
    dataSets: [],
    setDataSets: () => {
    },
    selectedDataSet: "",
    setSelectedDataSet: () => {
    }
})

export const ProjectProvider = (props) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState("");
    const [environments, setEnvironments] = useState([]);
    const [selectedEnvironment, setSelectedEnvironment] = useState("");
    const [dataSets, setDataSets] = useState([]);
    const [selectedDataSet, setSelectedDataSet] = useState("");
    const {Alert, status, showError} = useAlert();

    useEffect(() => {
        projectService.getProjects().then((response) => {
            const result = response.data;
            if (result) {
                setProjects(result);
                setSelectedProject(result[0]);
            }
        }).catch(error => {
            showError(error);
        });
        //eslint-disable-next-line
    }, []);

    const handleSelectedProject = (value) => {
        setSelectedProject(value)
    }

    return (
        <React.Fragment>
            {status ? <Alert/> : null}
            <ProjectContext.Provider value={{
                projects: projects,
                selectedProject: selectedProject,
                setSelectedProject: handleSelectedProject,
                clients: clients,
                setClients: setClients,
                selectedClient: selectedClient,
                setSelectedClient: setSelectedClient,
                environments: environments,
                setEnvironments: setEnvironments,
                selectedEnvironment: selectedEnvironment,
                setSelectedEnvironment: setSelectedEnvironment,
                dataSets: dataSets,
                setDataSets: setDataSets,
                selectedDataSet: selectedDataSet,
                setSelectedDataSet: setSelectedDataSet
            }}>
                {props.children}
            </ProjectContext.Provider>
        </React.Fragment>

    )
}

export default ProjectContext;